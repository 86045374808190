<script lang="ts" setup>
  const emit = defineEmits(['childEvent']);
	import { useMenuStore } from '~/stores/MenuStore';
	import { useSettingsStore } from '~/stores/SettingsStore';
	const menuStore = useMenuStore();
	const settingsStore = useSettingsStore();

	const active = ref(0);

	const toggle = (id: number, childrens: array) => {
		if (active.value == id) {
			active.value = 0;
		} else {
			active.value = id;
		}
    if (childrens.length === 0) {
      emit('toggleBurgerMenu')
    }
	};
</script>

<template>
  <div class="burger-menu">
		<div class="burger-menu__list">
			<div class="burger-menu__list-item" 
				v-for="(menuItem, index) in menuStore.top.slice(0, 5)" 
				:key="index"
			>
				<a
					class="burger-menu__list-link"
					:class="{active: menuItem.id == active}"
					@click="toggle(menuItem.id, menuItem.children)"
				>
					{{ menuItem.name }} 
					<span 
						class="arrow" 
						v-show="menuItem.children.length > 0"
					>
					</span>
				</a>
				<ul 
					class="burger-menu__list-dropdown" 
					v-if="menuItem.children.length > 0"
					:class="{active: menuItem.id == active}"
				>
					<li 
						v-for="item in menuItem.children" 
						:key="item.id"
					>
						<a href="#"></a>
						<nuxt-link 
							:to="item.url"
              @click.native="$emit('toggleBurgerMenu')"
							class="burger-menu__list-dropdown-link"
						>{{ item.name }}
						</nuxt-link>
					</li>
				</ul>
			</div>
			<div class="burger-menu__list-item" 
				v-for="(menuItem, index) in menuStore.top.slice(-2)" 
				:key="index"
			>
				<nuxt-link
					:to="menuItem.url"
					class="burger-menu__list-link"
					@click.native="$emit('toggleBurgerMenu')"
				>
					{{ menuItem.name }} 
				</nuxt-link>
			</div>
			<!-- <div class="burger-menu__list-item">
				<a href="javascript:void(0)" class="burger-menu__list-link"><span class="driver"></span> Аренда с водителем</a>
			</div>
			<div class="burger-menu__list-item">
				<a href="javascript:void(0)" class="burger-menu__list-link"> <span class="case"></span> Аренда для юрлиц</a>
			</div> -->
		</div>
		<div class="burger-menu__bottom">
			<div class="burger-menu__phone">
				<div class="burger-menu__text">Забронировать по телефону</div>
				<a :href="`tel:${settingsStore.phone}`" class="burger-menu__phone-link">{{ settingsStore.phone }}</a>
			</div>
			<div class="burger-menu__socials">
				<div class="burger-menu__text">Задать вопрос в мессенджерах</div>
				<div class="burger-menu__socials-content">
					<a href="https://t.me/mycarrental_support_bot" target="_blank" class="burger-menu__socials-item burger-menu__socials-item--telegram"></a>
					<a href="https://api.whatsapp.com/send/?phone=74957779930&text&type=phone_number&app_absent=0" target="_blank" class="burger-menu__socials-item burger-menu__socials-item--whatsapp"></a>
				</div>
			</div>
		</div>
	</div>
</template>