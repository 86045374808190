<script lang="ts" setup>
	import { defineProps } from 'vue'
	const nuxtApp = useNuxtApp();
	// import { useMenuStore } from '~/stores/MenuStore';
	// const menuStore = useMenuStore();
	const route = useRoute()

	const burgerActive = ref(false);
	const active = ref(0);

	const toggleBurgerMenu = () => {
		burgerActive.value = !burgerActive.value
		if (window.innerWidth < 761) {
			if (burgerActive.value === true) {
				nuxtApp.$disablePageScroll();
			} else {
				nuxtApp.$enablePageScroll();
			}
		}
	};

	const toggle = (id: number, childrens: array) => {
		if (active.value == id) {
			active.value = 0;
		} else {
			active.value = id;
		}
	};

	const prop = defineProps({
	  opacity: { default: false, type: [Boolean] },
	  menuStore: { default: false, type: [Object] },
	})

	onMounted(() => {
		if (route.query.ref) {
			localStorage.setItem('ref', route.query.ref)
		}
		if (route.query.marker) {
			localStorage.setItem('marker', route.query.marker)
		}
		if (route.query.trace_id) {
			localStorage.setItem('trace_id', route.query.trace_id)
		}
		if (route.query.partner) {
			localStorage.setItem('partner', route.query.partner)
		}
	})

</script>

<template>
	<div>
		<header class="header" :class="{opacity: opacity}">
			<div class="container">
				<div class="header__content">
					<nuxt-link 
						to="/" 
						class="header__logo"
					>
					</nuxt-link>
					<div class="header__burger">
						<div 
							class="header__burger-btn" 
							@click="toggleBurgerMenu"
							:class="{active: burgerActive}"
						>
							<span></span>
							<span></span>
							<span></span>
						</div>
						<div 
							class="header__dropdown"
							:class="{active: burgerActive}"
						>
							<div class="header__dropdown-item" v-for="(menuItem, index) in menuStore" :key="index">
								<a 
									class="header__dropdown-link"
									:class="{active: menuItem.id == active}"
									@click="toggle(menuItem.id, menuItem.children)"
								>
									{{ menuItem.name }} 
									<span class="arrow" v-show="menuItem.children.length > 0"></span>
								</a>
								<ul class="header__dropdown-list" :class="{active: menuItem.id == active}" v-if="menuItem.children.length > 0">
									<li v-for="(item, index) in menuItem.children" :key="index"><a href="#"></a><nuxt-link :to="item.url" @click.native="toggleBurgerMenu">{{ item.name }}</nuxt-link></li>
								</ul>
							</div>
						</div>
					</div>
					
					<div class="header__menu">
						<div class="header__menu-item" v-for="(menuItem, index) in menuStore.slice(0, 5)" :key="index">
							<nuxt-link :to="menuItem.url" class="header__text">
								{{ menuItem.name }} 
								<span class="arrow" v-show="menuItem.children.length > 0"></span>
							</nuxt-link>
							<ul class="header__menu-dropdown" v-if="menuItem.children.length > 0">
								<li v-for="(item, index) in menuItem.children" :key="index">
									<nuxt-link :to="item.url">{{ item.name }}</nuxt-link>
								</li>
							</ul>
						</div>
					</div>
					<!-- <div class="header__options">
						<a href="javascript:void(0)" class="header__text"><span class="icon driver"></span> Аренда с водителем</a>
						<a href="javascript:void(0)" class="header__text"><span class="icon case"></span> Аренда для юрлиц</a>
					</div> -->
					<div class="header__right">
						<div class="header__right-item" v-for="(menuItem, index) in menuStore.slice(-2)" :key="index">
							<nuxt-link :to="menuItem.url" class="header__text">
								{{ menuItem.name }} 
							</nuxt-link>
						</div>
					</div>
				</div>
			</div>
		</header>
		<Burger :class="{active: burgerActive}" @toggleBurgerMenu="toggleBurgerMenu" />
	</div>
</template>

<style>
.header__right-item {
	margin-left: 21px;
}
</style>